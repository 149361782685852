import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IPrediction_Result_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IPrediction_Result_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GetPrediction: any[];
  GetPrediction_dummy: any[];
  GetProject: any[];
  GetProject_dummy: any[];
  GetPredictionMetric: any[];
  GetPredictionMetric_dummy: any[];
  GetGCPAuthToken: string;
  GetGCPAuthToken_dummy: string;
  GetPredictionResultsFromBucket: any;
  GetPredictionResultsFromBucket_dummy: any;
  Notify: boolean;
  Notify_dummy: boolean;
  StringToBase64: string;
  StringToBase64_dummy: string;
  DownloadFile: any;
  DownloadFile_dummy: any;
  UpdatePredictionResults: number;
  UpdatePredictionResults_dummy: number;
  GoBack: any;
  GoBack_dummy: any;
}

export class Prediction_Result_ScreenBase extends React.PureComponent<IPrediction_Result_ScreenProps, any> {
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "73bde9df-e02a-4291-9c0f-19a6a47ee78b",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 333674, PropertyName: "value", Value: " metriği için sonuç dosyası (CSV) kaydedilsin mi?" },
        { Id: 815520, PropertyName: "label", Value: "Evet" },
        { Id: 965203, PropertyName: "label", Value: "Hayır" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GetPrediction: [],
      GetProject: [],
      GetPredictionMetric: [],
      GetGCPAuthToken: "",
      GetPredictionResultsFromBucket: "",
      Notify: false,
      StringToBase64: "",
      DownloadFile: "",
      UpdatePredictionResults: 0,
      GoBack: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("prediction_result", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Prediction_ResultPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("prediction_result", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("prediction_result", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Prediction_ResultPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  Prediction_ResultPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      PredictionID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.PredictionId ?? this.props.screenInputs.predictionid,
        "Guid"
      ),
      ProjectID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.GetPrediction?.length > 0
            ? stateVars.GetPrediction[0].projectID
            : this.state.GetPrediction?.length > 0
            ? this.state.GetPrediction[0].projectID
            : null
        ),
        "Guid"
      ),
      PredictionID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.PredictionId ?? this.props.screenInputs.predictionid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Prediction_Result/Prediction_ResultPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetPrediction = result?.data.getPrediction;
    stateVars.GetProject = result?.data.getProject;
    formVars.prediction_result_501719_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].projectName
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].projectName
        : null
    );
    stateVars.GetPredictionMetric = result?.data.getPredictionMetric;
    formVars.prediction_result_262814_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetPredictionMetric?.length > 0
        ? stateVars.GetPredictionMetric[0].metricName
        : this.state.GetPredictionMetric?.length > 0
        ? this.state.GetPredictionMetric[0].metricName
        : null
    );
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Prediction_ResultPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ResultPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.prediction_result_501719_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].projectName
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].projectName
        : null
    );

    formVars.prediction_result_262814_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetPredictionMetric?.length > 0
        ? stateVars.GetPredictionMetric[0].metricName
        : this.state.GetPredictionMetric?.length > 0
        ? this.state.GetPredictionMetric[0].metricName
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Prediction_ResultComponent_815520_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      predictionId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.PredictionId ?? this.props.screenInputs.predictionid,
        "string"
      ),
      gcpAccessToken_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.GetGCPAuthToken),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Prediction_Result/Prediction_ResultComponent_815520_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetGCPAuthToken = result?.data.getGCPAuthToken;
    stateVars.GetPredictionResultsFromBucket = result?.data.getPredictionResultsFromBucket;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Prediction_ResultComponent_815520_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ResultComponent_815520_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.GetPredictionResultsFromBucket?.result),
        "error"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.toString(this, this.state.GetPredictionResultsFromBucket?.message),
        ReactSystemFunctions.convertToTypeByName("danger", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("5", "number"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("0", "number")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ResultComponent_815520_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ResultComponent_815520_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Prediction_ResultComponent_815520_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      str_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetPredictionResultsFromBucket?.predictionResults?.resultCsv),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.GetPredictionResultsFromBucket?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Prediction_Result/Prediction_ResultComponent_815520_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.StringToBase64 = result?.data.stringToBase64;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ResultComponent_815520_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ResultComponent_815520_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ResultComponent_815520_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.GetPredictionResultsFromBucket?.result),
        "success"
      )
    ) {
      stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
        ReactSystemFunctions.toString(this, this.state.StringToBase64),
        ReactSystemFunctions.convertToTypeByName("Predictions.csv", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ResultComponent_815520_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Prediction_ResultComponent_815520_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Prediction_ResultComponent_815520_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ResultJson_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetPredictionResultsFromBucket?.predictionResults?.resultJson),
        "string"
      ),
      ResultCsv_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetPredictionResultsFromBucket?.predictionResults?.resultCsv),
        "string"
      ),
      PredictionId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.PredictionId ?? this.props.screenInputs.predictionid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.GetPredictionResultsFromBucket?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Prediction_Result/Prediction_ResultComponent_815520_onClick4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UpdatePredictionResults = result?.data.updatePredictionResults;
      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  Prediction_ResultComponent_965203_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
