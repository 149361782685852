import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBenchmark_Project_Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBenchmark_Project_Form_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GetAllSectors: any[];
  GetAllSectors_dummy: any[];
  GetAllArchitectures: any[];
  GetAllArchitectures_dummy: any[];
  GetAllSoftwareDevelopmentTypes: any[];
  GetAllSoftwareDevelopmentTypes_dummy: any[];
  GetAllSoftwareDevelopmentMethodologies: any[];
  GetAllSoftwareDevelopmentMethodologies_dummy: any[];
  GetAllSdlcActivities: any[];
  GetAllSdlcActivities_dummy: any[];
  GetAllProgrammingLanguages: any[];
  GetAllProgrammingLanguages_dummy: any[];
  MyContext: any[];
  MyContext_dummy: any[];
  GetBenchmarkProject: any[];
  GetBenchmarkProject_dummy: any[];
  GoBack: any;
  GoBack_dummy: any;
  ValidateBenchmarkProjectEventCosmic: boolean;
  ValidateBenchmarkProjectEventCosmic_dummy: boolean;
  Notify: boolean;
  Notify_dummy: boolean;
  UpsertBenchmarkProject: any[];
  UpsertBenchmarkProject_dummy: any[];
  isComp161357Visible: "visible" | "hidden";
  isComp316154Visible: "visible" | "hidden";
}

export class Benchmark_Project_Form_ScreenBase extends React.PureComponent<IBenchmark_Project_Form_ScreenProps, any> {
  benchmark_project_form_566494_value_kuikaSelectBoxRef: React.RefObject<any>;
  benchmark_project_form_570990_value_kuikaSelectBoxRef: React.RefObject<any>;
  benchmark_project_form_75394_value_kuikaSelectBoxRef: React.RefObject<any>;
  benchmark_project_form_394647_value_kuikaSelectBoxRef: React.RefObject<any>;
  benchmark_project_form_219476_value_kuikaMultiSelectRef: React.RefObject<any>;
  benchmark_project_form_972845_value_kuikaMultiSelectRef: React.RefObject<any>;
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "0312a17f-846b-4833-be53-45e216ad67d0",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 161357, PropertyName: "value", Value: "Benchmarking Verileri - Yeni" },
        { Id: 316154, PropertyName: "value", Value: "Benchmarking Verileri - Güncelle" },
        { Id: 669073, PropertyName: "value", Value: "Proje Adı" },
        { Id: 173286, PropertyName: "placeholder", Value: "Proje Adı" },
        { Id: 962169, PropertyName: "value", Value: "Sektör" },
        { Id: 566494, PropertyName: "placeholder", Value: "Seç" },
        { Id: 29360, PropertyName: "value", Value: "Mimari" },
        { Id: 570990, PropertyName: "placeholder", Value: "Seç" },
        { Id: 693892, PropertyName: "value", Value: "Yazılım Geliştirme Türü" },
        { Id: 75394, PropertyName: "placeholder", Value: "Seç" },
        { Id: 627195, PropertyName: "value", Value: "Yazılım Geliştirme Metodolojisi" },
        { Id: 394647, PropertyName: "placeholder", Value: "Seç" },
        { Id: 553307, PropertyName: "value", Value: "SDLC Aktiviteleri" },
        { Id: 219476, PropertyName: "placeholder", Value: "Seç" },
        { Id: 548252, PropertyName: "value", Value: "Programlama Dilleri" },
        { Id: 972845, PropertyName: "placeholder", Value: "Seç" },
        { Id: 342093, PropertyName: "value", Value: "Efor (Kişi/Saat)" },
        { Id: 446185, PropertyName: "value", Value: "Cosmic" },
        { Id: 810958, PropertyName: "value", Value: "Entry" },
        { Id: 398920, PropertyName: "value", Value: "Read" },
        { Id: 161656, PropertyName: "value", Value: "Write" },
        { Id: 246853, PropertyName: "value", Value: "Exit" },
        { Id: 208885, PropertyName: "value", Value: "Toplam CFP" },
        { Id: 602304, PropertyName: "value", Value: "Event" },
        { Id: 192815, PropertyName: "value", Value: "Interaction" },
        { Id: 119138, PropertyName: "value", Value: "Communication" },
        { Id: 94007, PropertyName: "value", Value: "Process" },
        { Id: 341153, PropertyName: "value", Value: "Toplam EP" },
        { Id: 995586, PropertyName: "label", Value: "Kaydet" },
        { Id: 565307, PropertyName: "label", Value: "İptal" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.benchmark_project_form_173286_value_kuikaTextInputRef = React.createRef();
    this.benchmark_project_form_566494_value_kuikaSelectBoxRef = React.createRef();
    this.benchmark_project_form_570990_value_kuikaSelectBoxRef = React.createRef();
    this.benchmark_project_form_75394_value_kuikaSelectBoxRef = React.createRef();
    this.benchmark_project_form_394647_value_kuikaSelectBoxRef = React.createRef();
    this.benchmark_project_form_219476_value_kuikaMultiSelectRef = React.createRef();
    this.benchmark_project_form_972845_value_kuikaMultiSelectRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GetAllSectors: [],
      GetAllArchitectures: [],
      GetAllSoftwareDevelopmentTypes: [],
      GetAllSoftwareDevelopmentMethodologies: [],
      GetAllSdlcActivities: [],
      GetAllProgrammingLanguages: [],
      MyContext: [],
      GetBenchmarkProject: [],
      GoBack: "",
      ValidateBenchmarkProjectEventCosmic: false,
      Notify: false,
      UpsertBenchmarkProject: [],
      isComp161357Visible: "hidden",
      isComp316154Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("benchmark_project_form", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Benchmark_Project_FormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("benchmark_project_form", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("benchmark_project_form", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Benchmark_Project_FormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      benchmark_project_form_173286_value: this.state.GetBenchmarkProject?.at?.(0)?.projectName ?? undefined,
      benchmark_project_form_566494_value: this.state.GetBenchmarkProject?.at?.(0)?.sectorId ?? undefined,
      benchmark_project_form_570990_value: this.state.GetBenchmarkProject?.at?.(0)?.architectureId ?? undefined,
      benchmark_project_form_75394_value:
        this.state.GetBenchmarkProject?.at?.(0)?.softwareDevelopmentTypeId ?? undefined,
      benchmark_project_form_394647_value:
        this.state.GetBenchmarkProject?.at?.(0)?.softwareDevelopmentMethodologyId ?? undefined,
      benchmark_project_form_219476_value: this.state.GetBenchmarkProject?.at?.(0)?.sdlcActivityIds ?? undefined,
      benchmark_project_form_972845_value: this.state.GetBenchmarkProject?.at?.(0)?.programmingLanguageIds ?? undefined,
      benchmark_project_form_49741_value: this.state.GetBenchmarkProject?.at?.(0)?.effort ?? undefined,
      benchmark_project_form_779718_value: this.state.GetBenchmarkProject?.at?.(0)?.cosmicEntry ?? undefined,
      benchmark_project_form_141672_value: this.state.GetBenchmarkProject?.at?.(0)?.cosmicRead ?? undefined,
      benchmark_project_form_265390_value: this.state.GetBenchmarkProject?.at?.(0)?.cosmicWrite ?? undefined,
      benchmark_project_form_465390_value: this.state.GetBenchmarkProject?.at?.(0)?.cosmicExit ?? undefined,
      benchmark_project_form_224621_value: this.state.GetBenchmarkProject?.at?.(0)?.cosmicTotalCfp ?? undefined,
      benchmark_project_form_347010_value: this.state.GetBenchmarkProject?.at?.(0)?.eventInteraction ?? undefined,
      benchmark_project_form_843628_value: this.state.GetBenchmarkProject?.at?.(0)?.eventCommunication ?? undefined,
      benchmark_project_form_229849_value: this.state.GetBenchmarkProject?.at?.(0)?.eventProcess ?? undefined,
      benchmark_project_form_801673_value: this.state.GetBenchmarkProject?.at?.(0)?.eventTotalEp ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  Benchmark_Project_FormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_6: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Project_Form/Benchmark_Project_FormPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetAllSectors = result?.data.getAllSectors;

    formVars.benchmark_project_form_566494_options = stateVars.GetAllSectors;
    stateVars.GetAllArchitectures = result?.data.getAllArchitectures;

    formVars.benchmark_project_form_570990_options = stateVars.GetAllArchitectures;
    stateVars.GetAllSoftwareDevelopmentTypes = result?.data.getAllSoftwareDevelopmentTypes;

    formVars.benchmark_project_form_75394_options = stateVars.GetAllSoftwareDevelopmentTypes;
    stateVars.GetAllSoftwareDevelopmentMethodologies = result?.data.getAllSoftwareDevelopmentMethodologies;

    formVars.benchmark_project_form_394647_options = stateVars.GetAllSoftwareDevelopmentMethodologies;
    stateVars.GetAllSdlcActivities = result?.data.getAllSdlcActivities;

    formVars.benchmark_project_form_219476_options = stateVars.GetAllSdlcActivities;
    stateVars.GetAllProgrammingLanguages = result?.data.getAllProgrammingLanguages;

    formVars.benchmark_project_form_972845_options = stateVars.GetAllProgrammingLanguages;
    stateVars.MyContext = result?.data.myContext;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Project_FormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      ProjectId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid,
        "Guid"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Project_Form/Benchmark_Project_FormPageInit1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GetBenchmarkProject = result?.data.getBenchmarkProject;
      formVars.benchmark_project_form_173286_value = ReactSystemFunctions.toString(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].projectName
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].projectName
          : null
      );
      formVars.benchmark_project_form_566494_value =
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].sectorId
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].sectorId
          : null;
      formVars.benchmark_project_form_570990_value =
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].architectureId
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].architectureId
          : null;
      formVars.benchmark_project_form_75394_value =
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].softwareDevelopmentTypeId
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].softwareDevelopmentTypeId
          : null;
      formVars.benchmark_project_form_394647_value =
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].softwareDevelopmentMethodologyId
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].softwareDevelopmentMethodologyId
          : null;
      formVars.benchmark_project_form_219476_value =
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].sdlcActivityIds
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].sdlcActivityIds
          : null;
      formVars.benchmark_project_form_972845_value =
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].programmingLanguageIds
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].programmingLanguageIds
          : null;
      formVars.benchmark_project_form_49741_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].effort
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].effort
          : null
      );
      formVars.benchmark_project_form_779718_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].cosmicEntry
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].cosmicEntry
          : null
      );
      formVars.benchmark_project_form_141672_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].cosmicRead
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].cosmicRead
          : null
      );
      formVars.benchmark_project_form_265390_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].cosmicWrite
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].cosmicWrite
          : null
      );
      formVars.benchmark_project_form_465390_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].cosmicExit
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].cosmicExit
          : null
      );
      formVars.benchmark_project_form_224621_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].cosmicTotalCfp
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].cosmicTotalCfp
          : null
      );
      formVars.benchmark_project_form_347010_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].eventInteraction
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].eventInteraction
          : null
      );
      formVars.benchmark_project_form_843628_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].eventCommunication
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].eventCommunication
          : null
      );
      formVars.benchmark_project_form_229849_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].eventProcess
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].eventProcess
          : null
      );
      formVars.benchmark_project_form_801673_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].eventTotalEp
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].eventTotalEp
          : null
      );
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp161357Visible =
      ReactSystemFunctions.isEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, false) ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp316154Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, true) ===
      true
        ? "visible"
        : "hidden";

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Project_FormPageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, null)) {
      formVars.benchmark_project_form_173286_value = ReactSystemFunctions.toString(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].projectName
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].projectName
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, null)) {
      formVars.benchmark_project_form_566494_value = ReactSystemFunctions.toString(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].sectorId
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].sectorId
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_566494 = this.state.GetAllSectors;
    stateVars.dataSource_566494 = this.state.GetAllSectors;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Project_FormPageInit6_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, null)) {
      formVars.benchmark_project_form_570990_value = ReactSystemFunctions.toString(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].architectureId
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].architectureId
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_570990 = this.state.GetAllArchitectures;
    stateVars.dataSource_570990 = this.state.GetAllArchitectures;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Project_FormPageInit8_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, null)) {
      formVars.benchmark_project_form_75394_value = ReactSystemFunctions.toString(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].softwareDevelopmentTypeId
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].softwareDevelopmentTypeId
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_75394 = this.state.GetAllSoftwareDevelopmentTypes;
    stateVars.dataSource_75394 = this.state.GetAllSoftwareDevelopmentTypes;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Project_FormPageInit10_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, null)) {
      formVars.benchmark_project_form_394647_value = ReactSystemFunctions.toString(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].softwareDevelopmentMethodologyId
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].softwareDevelopmentMethodologyId
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_394647 = this.state.GetAllSoftwareDevelopmentMethodologies;
    stateVars.dataSource_394647 = this.state.GetAllSoftwareDevelopmentMethodologies;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Project_FormPageInit12_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, null)) {
      formVars.benchmark_project_form_219476_value = ReactSystemFunctions.toString(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].sdlcActivityIds
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].sdlcActivityIds
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_219476 = this.state.GetAllSdlcActivities;
    stateVars.dataSource_219476 = this.state.GetAllSdlcActivities;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Project_FormPageInit14_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, null)) {
      formVars.benchmark_project_form_972845_value = ReactSystemFunctions.toString(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].programmingLanguageIds
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].programmingLanguageIds
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit15_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_972845 = this.state.GetAllProgrammingLanguages;
    stateVars.dataSource_972845 = this.state.GetAllProgrammingLanguages;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Project_FormPageInit16_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit16_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, null)) {
      formVars.benchmark_project_form_49741_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].effort
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].effort
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit17_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit17_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit17_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, null)) {
      formVars.benchmark_project_form_779718_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].cosmicEntry
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].cosmicEntry
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit18_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit18_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit18_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, null)) {
      formVars.benchmark_project_form_141672_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].cosmicRead
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].cosmicRead
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit19_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit19_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit19_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, null)) {
      formVars.benchmark_project_form_265390_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].cosmicWrite
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].cosmicWrite
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit20_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit20_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit20_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, null)) {
      formVars.benchmark_project_form_465390_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].cosmicExit
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].cosmicExit
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit21_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit21_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit21_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, null)) {
      formVars.benchmark_project_form_224621_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].cosmicTotalCfp
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].cosmicTotalCfp
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit22_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit22_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit22_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, null)) {
      formVars.benchmark_project_form_347010_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].eventInteraction
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].eventInteraction
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit23_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit23_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit23_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, null)) {
      formVars.benchmark_project_form_843628_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].eventCommunication
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].eventCommunication
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit24_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit24_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit24_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, null)) {
      formVars.benchmark_project_form_229849_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].eventProcess
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].eventProcess
          : null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit25_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormPageInit25_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Project_FormPageInit25_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid, null)) {
      formVars.benchmark_project_form_801673_value = ReactSystemFunctions.value(
        this,
        stateVars.GetBenchmarkProject?.length > 0
          ? stateVars.GetBenchmarkProject[0].eventTotalEp
          : this.state.GetBenchmarkProject?.length > 0
          ? this.state.GetBenchmarkProject[0].eventTotalEp
          : null
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Benchmark_Project_FormComponent_861322_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Benchmark_Project_FormComponent_995586_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_779718_value", "value", "", "", "")
        ),
        "number"
      ),
      read_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_141672_value", "value", "", "", "")
        ),
        "number"
      ),
      write_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_265390_value", "value", "", "", "")
        ),
        "number"
      ),
      exit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_465390_value", "value", "", "", "")
        ),
        "number"
      ),
      totalCfp_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_224621_value", "value", "", "", "")
        ),
        "number"
      ),
      interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_347010_value", "value", "", "", "")
        ),
        "number"
      ),
      communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_843628_value", "value", "", "", "")
        ),
        "number"
      ),
      process_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_229849_value", "value", "", "", "")
        ),
        "number"
      ),
      totalEp_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_801673_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Benchmark_Project_Form/Benchmark_Project_FormComponent_995586_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ValidateBenchmarkProjectEventCosmic = result?.data.validateBenchmarkProjectEventCosmic;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Benchmark_Project_FormComponent_995586_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Project_FormComponent_995586_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.ValidateBenchmarkProjectEventCosmic),
        false
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "f81bdfc4_7717_e7c5_1127_7430490a5135_notify",
          this.defaultML,
          "Toplam CFP ya da Toplam EP değerlerinden en az biri 0'dan büyük olmalı."
        ),
        ReactSystemFunctions.convertToTypeByName("danger", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("5", "number"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("0", "number")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormComponent_995586_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormComponent_995586_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Benchmark_Project_FormComponent_995586_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_173286_value", "value", "", "", "")
        ),
        null
      ),
      message: "Model adı gerekli.",
      formName: "benchmark_project_form_173286_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_49741_value", "value", "", "", "")
        ),
        null
      ),
      message: "Efor gerekli.",
      formName: "benchmark_project_form_49741_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_566494_value", "value", "GetAllSectors", "id", "")
        ),
        null
      ),
      message: "Sektör seçimi gerekli.",
      formName: "benchmark_project_form_566494_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_project_form_570990_value",
            "value",
            "GetAllArchitectures",
            "id",
            ""
          )
        ),
        null
      ),
      message: "Mimari seçimi gerekli.",
      formName: "benchmark_project_form_570990_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_project_form_394647_value",
            "value",
            "GetAllSoftwareDevelopmentMethodologies",
            "id",
            ""
          )
        ),
        null
      ),
      message: "Yazılım geliştirme metodolojisi seçimi gerekli.",
      formName: "benchmark_project_form_394647_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_project_form_75394_value",
            "value",
            "GetAllSoftwareDevelopmentTypes",
            "id",
            ""
          )
        ),
        null
      ),
      message: "Yazılım geliştirme türü seçimi gerekli.",
      formName: "benchmark_project_form_75394_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_project_form_972845_value",
            "value",
            "GetAllProgrammingLanguages",
            "id",
            ""
          )
        ),
        null
      ),
      message: "En az 1 adet programlama dili",
      formName: "benchmark_project_form_972845_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_project_form_219476_value",
            "value",
            "GetAllSdlcActivities",
            "id",
            ""
          )
        ),
        null
      ),
      message: "En az 1 adet SDLC aktivitesi seçimi gerekli.",
      formName: "benchmark_project_form_219476_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectId ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      ProjectName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_173286_value", "value", "", "", "")
        ),
        "string"
      ),
      Effort_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_49741_value", "value", "", "", "")
        ),
        "number"
      ),
      CompanyId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      CreatedBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      LastUpdatedBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      EventCommunication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_843628_value", "value", "", "", "")
        ),
        "number"
      ),
      EventInteraction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_347010_value", "value", "", "", "")
        ),
        "number"
      ),
      EventProcess_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_229849_value", "value", "", "", "")
        ),
        "number"
      ),
      EventTotalEp_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_801673_value", "value", "", "", "")
        ),
        "number"
      ),
      CosmicEntry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_779718_value", "value", "", "", "")
        ),
        "number"
      ),
      CosmicRead_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_141672_value", "value", "", "", "")
        ),
        "number"
      ),
      CosmicWrite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_265390_value", "value", "", "", "")
        ),
        "number"
      ),
      CosmicExit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_465390_value", "value", "", "", "")
        ),
        "number"
      ),
      CosmicTotalCfp_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_224621_value", "value", "", "", "")
        ),
        "number"
      ),
      SectorId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "benchmark_project_form_566494_value", "value", "GetAllSectors", "id", "id")
        ),
        "Guid"
      ),
      ArchitectureId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_project_form_570990_value",
            "value",
            "GetAllArchitectures",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      SoftwareDevelopmentMethodologyId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_project_form_394647_value",
            "value",
            "GetAllSoftwareDevelopmentMethodologies",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      SoftwareDevelopmentTypeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_project_form_75394_value",
            "value",
            "GetAllSoftwareDevelopmentTypes",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      ProgrammingLanguages_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_project_form_972845_value",
            "value",
            "GetAllProgrammingLanguages",
            "id",
            "id"
          )
        ),
        "string"
      ),
      SdlcActivities_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "benchmark_project_form_219476_value",
            "value",
            "GetAllSdlcActivities",
            "id",
            "id"
          )
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.ValidateBenchmarkProjectEventCosmic),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Benchmark_Project_Form/Benchmark_Project_FormComponent_995586_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UpsertBenchmarkProject = result?.data.upsertBenchmarkProject;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormComponent_995586_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Benchmark_Project_FormComponent_995586_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Benchmark_Project_FormComponent_995586_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.ValidateBenchmarkProjectEventCosmic),
        true
      )
    ) {
      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  Benchmark_Project_FormComponent_565307_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
