import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IModel_Training_List_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IModel_Training_List_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  IsUserDisabled: any[];
  IsUserDisabled_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  GetStatusDropDown: any[];
  GetStatusDropDown_dummy: any[];
  MyContext: any[];
  MyContext_dummy: any[];
  GetConfigFromFile: any;
  GetConfigFromFile_dummy: any;
  GetOngoingModelsAsJson: any[];
  GetOngoingModelsAsJson_dummy: any[];
  GetGCPAuthToken: string;
  GetGCPAuthToken_dummy: string;
  CheckVertexAiCustomJobsStatus: string;
  CheckVertexAiCustomJobsStatus_dummy: string;
  UpdateOngoingModel: number;
  UpdateOngoingModel_dummy: number;
  GetModels: any[];
  GetModels_dummy: any[];
  GetModelCount: any[];
  GetModelCount_dummy: any[];
  DeleteModel: number;
  DeleteModel_dummy: number;
  ModelResultsExist: any[];
  ModelResultsExist_dummy: any[];
  GetTrainingResultsFromBucket: any;
  GetTrainingResultsFromBucket_dummy: any;
  Notify: boolean;
  Notify_dummy: boolean;
  UpdateModelResults: number;
  UpdateModelResults_dummy: number;
  CancelVertexAiCustomJob: any;
  CancelVertexAiCustomJob_dummy: any;
  UpdateModelAfterTrainingCancel: number;
  UpdateModelAfterTrainingCancel_dummy: number;
  UpdateModelOrPredictionAfterCancelFail: number;
  UpdateModelOrPredictionAfterCancelFail_dummy: number;
  GetProjectRequirementsAsJson: any[];
  GetProjectRequirementsAsJson_dummy: any[];
  SaveJsonToBucket: any;
  SaveJsonToBucket_dummy: any;
  GetParentModelPathFromParentModelId: string;
  GetParentModelPathFromParentModelId_dummy: string;
  GetVertexAiApiCompatibleTargetKey: string;
  GetVertexAiApiCompatibleTargetKey_dummy: string;
  CreateVertexAiCustomJob: any;
  CreateVertexAiCustomJob_dummy: any;
  UpdateModelAfterTrainingStart: number;
  UpdateModelAfterTrainingStart_dummy: number;
  isComp155052Enabled: "enabled" | "disabled";
  isComp485487Enabled: "enabled" | "disabled";
  isComp14934Enabled: "enabled" | "disabled";
  isComp252302Enabled: "enabled" | "disabled";
  isComp573776Enabled: "enabled" | "disabled";

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
  shouldTriggerPageInit?: boolean;
}

export class Model_Training_List_ScreenBase extends React.PureComponent<IModel_Training_List_ScreenProps, any> {
  model_training_list_164601_value_kuikaSelectBoxRef: React.RefObject<any>;
  model_training_list_795226_value_kuikaTableRef: React.RefObject<any>;
  model_training_list_658618_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "914e66db-05a5-464a-806c-c5d68dab3c85",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 958134, PropertyName: "value", Value: "Model Eğitim İşlemleri" },
        { Id: 551893, PropertyName: "label", Value: "Ekle" },
        { Id: 933893, PropertyName: "label", Value: "Yenile" },
        { Id: 945581, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 795226, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 809316, PropertyName: "title", Value: "Model Adı" },
        { Id: 979789, PropertyName: "value", Value: "[datafield:modelname]" },
        { Id: 143445, PropertyName: "title", Value: "Metrik" },
        { Id: 112340, PropertyName: "value", Value: "[datafield:metricname]" },
        { Id: 228361, PropertyName: "title", Value: "Proje Kodu" },
        { Id: 884182, PropertyName: "value", Value: "[datafield:projectcode]" },
        { Id: 758027, PropertyName: "title", Value: "Proje Adı" },
        { Id: 922220, PropertyName: "value", Value: "[datafield:projectname]" },
        { Id: 110227, PropertyName: "title", Value: "Proje Dili" },
        { Id: 88391, PropertyName: "value", Value: "[datafield:language]" },
        { Id: 39612, PropertyName: "title", Value: "Durum" },
        { Id: 789441, PropertyName: "value", Value: "[datafield:statusname]" },
        { Id: 313383, PropertyName: "title", Value: "Baz Model" },
        { Id: 105826, PropertyName: "value", Value: "[datafield:parentmodel]" },
        { Id: 176956, PropertyName: "title", Value: "Başlangıç Tarihi" },
        { Id: 806157, PropertyName: "value", Value: "[datafield:starttime]" },
        { Id: 412285, PropertyName: "title", Value: "Bitiş Tarihi" },
        { Id: 977403, PropertyName: "value", Value: "[datafield:endtime]" },
        { Id: 217898, PropertyName: "value", Value: "Kayıt" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.model_training_list_164601_value_kuikaSelectBoxRef = React.createRef();
    this.model_training_list_945581_value_kuikaTextInputRef = React.createRef();
    this.model_training_list_795226_value_kuikaTableRef = React.createRef();
    this.model_training_list_658618_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      IsUserDisabled: [],
      NAVIGATE: "",
      GetStatusDropDown: [],
      MyContext: [],
      GetConfigFromFile: "",
      GetOngoingModelsAsJson: [],
      GetGCPAuthToken: "",
      CheckVertexAiCustomJobsStatus: "",
      UpdateOngoingModel: 0,
      GetModels: [],
      GetModelCount: [],
      DeleteModel: 0,
      ModelResultsExist: [],
      GetTrainingResultsFromBucket: "",
      Notify: false,
      UpdateModelResults: 0,
      CancelVertexAiCustomJob: "",
      UpdateModelAfterTrainingCancel: 0,
      UpdateModelOrPredictionAfterCancelFail: 0,
      GetProjectRequirementsAsJson: [],
      SaveJsonToBucket: "",
      GetParentModelPathFromParentModelId: "",
      GetVertexAiApiCompatibleTargetKey: "",
      CreateVertexAiCustomJob: "",
      UpdateModelAfterTrainingStart: 0,
      isComp155052Enabled: "disabled",
      isComp485487Enabled: "disabled",
      isComp14934Enabled: "disabled",
      isComp252302Enabled: "disabled",
      isComp573776Enabled: "disabled",

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false,
      shouldTriggerPageInit: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("model_training_list", "siskonmaster_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.Model_Training_ListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("model_training_list", "siskonmaster_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("model_training_list", "siskonmaster_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Model_Training_ListPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.Model_Training_ListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };
  setShouldTriggerPageInit = (value: boolean) => {
    this.setState({ shouldTriggerPageInit: value });
  };

  Model_Training_ListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Model_Training_List/Model_Training_ListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IsUserDisabled = result?.data.isUserDisabled;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Model_Training_ListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.IsUserDisabled?.length > 0
            ? stateVars.IsUserDisabled[0].result
            : this.state.IsUserDisabled?.length > 0
            ? this.state.IsUserDisabled[0].result
            : null
        ),
        "1"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.Model_Training_ListPageInit2_,
        "Model_Training_List",
        "DisabledAccount",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Model_Training_ListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      CompanyId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      gcpAuthToken_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.GetGCPAuthToken),
        "string"
      ),
      itemsJson_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GetOngoingModelsAsJson?.length > 0
            ? stateVars.GetOngoingModelsAsJson[0].ongoingModels
            : this.state.GetOngoingModelsAsJson?.length > 0
            ? this.state.GetOngoingModelsAsJson[0].ongoingModels
            : null
        ),
        "string"
      ),
      OngoingModelStatusResultsJson_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.CheckVertexAiCustomJobsStatus),
        "string"
      ),
      Status_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "current", "", "", "")
        ),
        "number"
      ),
      Status_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Model_Training_List/Model_Training_ListPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetStatusDropDown = result?.data.getStatusDropDown;

    formVars.model_training_list_164601_options = stateVars.GetStatusDropDown;
    stateVars.MyContext = result?.data.myContext;
    stateVars.GetConfigFromFile = result?.data.getConfigFromFile;
    stateVars.GetOngoingModelsAsJson = result?.data.getOngoingModelsAsJson;
    stateVars.GetGCPAuthToken = result?.data.getGCPAuthToken;
    stateVars.CheckVertexAiCustomJobsStatus = result?.data.checkVertexAiCustomJobsStatus;
    stateVars.UpdateOngoingModel = result?.data.updateOngoingModel;
    stateVars.GetModels = result?.data.getModels;

    stateVars.GetModelCount = result?.data.getModelCount;
    formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetModelCount?.length > 0
        ? stateVars.GetModelCount[0].modelCount
        : this.state.GetModelCount?.length > 0
        ? this.state.GetModelCount[0].modelCount
        : null
    );
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Model_Training_ListPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_164601 = this.state.GetStatusDropDown;
    stateVars.dataSource_164601 = this.state.GetStatusDropDown;

    stateVars.dataSource_795226 = this.state.GetModels;
    stateVars.isComp155052Enabled =
      (ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "statusName"),
        "Bitti"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(this, "model_training_list_795226_value", "statusName"),
          "Devam Ediyor"
        )) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp485487Enabled =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "statusName"),
        "Devam Ediyor"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp14934Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "statusName"),
        "Bitti"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp252302Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "statusName"),
        "Devam Ediyor"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp573776Enabled =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "statusName"),
        "Devam Ediyor"
      ) === true
        ? "enabled"
        : "disabled";

    formVars.model_training_list_658618_total = ReactSystemFunctions.value(
      this,
      stateVars.GetModelCount?.length > 0
        ? stateVars.GetModelCount[0].modelCount
        : this.state.GetModelCount?.length > 0
        ? this.state.GetModelCount[0].modelCount
        : null
    );

    formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetModelCount?.length > 0
        ? stateVars.GetModelCount[0].modelCount
        : this.state.GetModelCount?.length > 0
        ? this.state.GetModelCount[0].modelCount
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Model_Training_ListComponent_551893_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Model_Form",
      "ModelID",
      ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "Model_Form",
      "IsEdit",
      ReactSystemFunctions.convertToTypeByName("False", "boolean")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Model_Training_List",
      "Model_Form",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "511494",
      null,
      null,
      "true",
      "800px",
      "vh",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Model_Training_ListComponent_933893_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CompanyId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      gcpAuthToken_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.GetGCPAuthToken),
        "string"
      ),
      itemsJson_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GetOngoingModelsAsJson?.length > 0
            ? stateVars.GetOngoingModelsAsJson[0].ongoingModels
            : this.state.GetOngoingModelsAsJson?.length > 0
            ? this.state.GetOngoingModelsAsJson[0].ongoingModels
            : null
        ),
        "string"
      ),
      OngoingModelStatusResultsJson_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.CheckVertexAiCustomJobsStatus),
        "string"
      ),
      Status_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "current", "", "", "")
        ),
        "number"
      ),
      Status_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Model_Training_List/Model_Training_ListComponent_933893_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetOngoingModelsAsJson = result?.data.getOngoingModelsAsJson;
    stateVars.GetGCPAuthToken = result?.data.getGCPAuthToken;
    stateVars.CheckVertexAiCustomJobsStatus = result?.data.checkVertexAiCustomJobsStatus;
    stateVars.UpdateOngoingModel = result?.data.updateOngoingModel;
    stateVars.GetModels = result?.data.getModels;

    stateVars.GetModelCount = result?.data.getModelCount;
    formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetModelCount?.length > 0
        ? stateVars.GetModelCount[0].modelCount
        : this.state.GetModelCount?.length > 0
        ? this.state.GetModelCount[0].modelCount
        : null
    );
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Model_Training_ListComponent_933893_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_933893_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_795226 = this.state.GetModels;
    formVars.model_training_list_658618_total = ReactSystemFunctions.value(
      this,
      stateVars.GetModelCount?.length > 0
        ? stateVars.GetModelCount[0].modelCount
        : this.state.GetModelCount?.length > 0
        ? this.state.GetModelCount[0].modelCount
        : null
    );

    formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetModelCount?.length > 0
        ? stateVars.GetModelCount[0].modelCount
        : this.state.GetModelCount?.length > 0
        ? this.state.GetModelCount[0].modelCount
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Model_Training_ListComponent_164601_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Status_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "current", "", "", "")
        ),
        "number"
      ),
      Status_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Model_Training_List/Model_Training_ListComponent_164601_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetModels = result?.data.getModels;

    stateVars.GetModelCount = result?.data.getModelCount;
    formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetModelCount?.length > 0
        ? stateVars.GetModelCount[0].modelCount
        : this.state.GetModelCount?.length > 0
        ? this.state.GetModelCount[0].modelCount
        : null
    );
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Model_Training_ListComponent_164601_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_164601_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_795226 = this.state.GetModels;
    formVars.model_training_list_658618_total = ReactSystemFunctions.value(
      this,
      stateVars.GetModelCount?.length > 0
        ? stateVars.GetModelCount[0].modelCount
        : this.state.GetModelCount?.length > 0
        ? this.state.GetModelCount[0].modelCount
        : null
    );

    formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetModelCount?.length > 0
        ? stateVars.GetModelCount[0].modelCount
        : this.state.GetModelCount?.length > 0
        ? this.state.GetModelCount[0].modelCount
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Model_Training_ListComponent_945581_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Status_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "current", "", "", "")
        ),
        "number"
      ),
      Status_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Model_Training_List/Model_Training_ListComponent_945581_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetModels = result?.data.getModels;

    stateVars.GetModelCount = result?.data.getModelCount;
    formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetModelCount?.length > 0
        ? stateVars.GetModelCount[0].modelCount
        : this.state.GetModelCount?.length > 0
        ? this.state.GetModelCount[0].modelCount
        : null
    );
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Model_Training_ListComponent_945581_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_945581_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_795226 = this.state.GetModels;
    formVars.model_training_list_658618_total = ReactSystemFunctions.value(
      this,
      stateVars.GetModelCount?.length > 0
        ? stateVars.GetModelCount[0].modelCount
        : this.state.GetModelCount?.length > 0
        ? this.state.GetModelCount[0].modelCount
        : null
    );

    formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetModelCount?.length > 0
        ? stateVars.GetModelCount[0].modelCount
        : this.state.GetModelCount?.length > 0
        ? this.state.GetModelCount[0].modelCount
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Model_Training_ListComponent_155052_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Model_Form",
      "ModelID",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", ".id"),
        "Guid"
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "Model_Form",
      "IsEdit",
      ReactSystemFunctions.convertToTypeByName("True", "boolean")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Model_Training_List",
      "Model_Form",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "792729",
      null,
      null,
      "true",
      "800px",
      "vh",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Model_Training_ListComponent_485487_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ModelID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", ".id"),
        "Guid"
      ),
      Status_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "current", "", "", "")
        ),
        "number"
      ),
      Status_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "3f0ca7d0_bc31_7c0d_bc1b_9d66a6c94ad9_confirmation",
        this.defaultML,
        "Silmek istediğinize emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Model_Training_List/Model_Training_ListComponent_485487_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DeleteModel = result?.data.deleteModel;
      stateVars.GetModels = result?.data.getModels;

      stateVars.GetModelCount = result?.data.getModelCount;
      formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
        this,
        stateVars.GetModelCount?.length > 0
          ? stateVars.GetModelCount[0].modelCount
          : this.state.GetModelCount?.length > 0
          ? this.state.GetModelCount[0].modelCount
          : null
      );
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_485487_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_485487_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_795226 = this.state.GetModels;
    formVars.model_training_list_658618_total = ReactSystemFunctions.value(
      this,
      stateVars.GetModelCount?.length > 0
        ? stateVars.GetModelCount[0].modelCount
        : this.state.GetModelCount?.length > 0
        ? this.state.GetModelCount[0].modelCount
        : null
    );

    formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetModelCount?.length > 0
        ? stateVars.GetModelCount[0].modelCount
        : this.state.GetModelCount?.length > 0
        ? this.state.GetModelCount[0].modelCount
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Model_Training_ListComponent_14934_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ModelId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "modelID"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Model_Training_List/Model_Training_ListComponent_14934_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ModelResultsExist = result?.data.modelResultsExist;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Model_Training_ListComponent_14934_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_14934_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.ModelResultsExist?.length > 0
            ? stateVars.ModelResultsExist[0].modelResultsExist
            : this.state.ModelResultsExist?.length > 0
            ? this.state.ModelResultsExist[0].modelResultsExist
            : null
        ),
        "0"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Model_Training_List/Model_Training_ListComponent_14934_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GetGCPAuthToken = result?.data.getGCPAuthToken;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_14934_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_14934_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_14934_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      modelId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "id"),
        "string"
      ),
      gcpAccessToken_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetGCPAuthToken),
        "string"
      ),
      folderName_0: ReactSystemFunctions.convertToTypeByName("stats", "string")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.ModelResultsExist?.length > 0
            ? stateVars.ModelResultsExist[0].modelResultsExist
            : this.state.ModelResultsExist?.length > 0
            ? this.state.ModelResultsExist[0].modelResultsExist
            : null
        ),
        "0"
      ) &&
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "statusName"),
        "Bitti"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Model_Training_List/Model_Training_ListComponent_14934_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GetTrainingResultsFromBucket = result?.data.getTrainingResultsFromBucket;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_14934_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_14934_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_14934_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.GetTrainingResultsFromBucket?.result),
        "error"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.toString(this, this.state.GetTrainingResultsFromBucket?.message),
        ReactSystemFunctions.convertToTypeByName("danger", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("5", "number"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("0", "number")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_14934_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_14934_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Model_Training_ListComponent_14934_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Mmre_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.mmre),
        "number"
      ),
      MdMre_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.mdmre),
        "number"
      ),
      Pred30_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.pred30),
        "number"
      ),
      Mae_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.mae),
        "number"
      ),
      NMae_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.nmae),
        "number"
      ),
      Loss_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.loss),
        "number"
      ),
      ModelId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "modelID"),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.GetTrainingResultsFromBucket?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Model_Training_List/Model_Training_ListComponent_14934_onClick4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UpdateModelResults = result?.data.updateModelResults;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_14934_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_14934_onClick5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_14934_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Status_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "current", "", "", "")
        ),
        "number"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.GetTrainingResultsFromBucket?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Model_Training_List/Model_Training_ListComponent_14934_onClick5_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GetModels = result?.data.getModels;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_14934_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_14934_onClick6_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_14934_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.ModelResultsExist?.length > 0
            ? stateVars.ModelResultsExist[0].modelResultsExist
            : this.state.ModelResultsExist?.length > 0
            ? this.state.ModelResultsExist[0].modelResultsExist
            : null
        ),
        "1"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "MMRE",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "model_training_list_795226_value", "mmre"),
          "number"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "MdMMRE",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "model_training_list_795226_value", "mdMRE"),
          "number"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "PRED30",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "model_training_list_795226_value", "preD30"),
          "number"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "ModelName",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "model_training_list_795226_value", "modelName"),
          "string"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "MAE",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "model_training_list_795226_value", "mae"),
          "number"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "NMAE",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "model_training_list_795226_value", "nMae"),
          "number"
        )
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.Model_Training_ListComponent_14934_onClick7_,
        "Model_Training_List",
        "Model_Training_Result",
        "",
        "9924ef5f-4b84-4200-b624-8d76348c7686",
        "314747",
        null,
        null,
        "true",
        "800px",
        "70vh",
        true
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_14934_onClick7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Model_Training_ListComponent_14934_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.GetTrainingResultsFromBucket?.result),
        "success"
      ) &&
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.ModelResultsExist?.length > 0
            ? stateVars.ModelResultsExist[0].modelResultsExist
            : this.state.ModelResultsExist?.length > 0
            ? this.state.ModelResultsExist[0].modelResultsExist
            : null
        ),
        "0"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "MMRE",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.mmre),
          "number"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "MdMMRE",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.mdmre),
          "number"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "PRED30",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.pred30),
          "number"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "ModelName",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "model_training_list_795226_value", "modelName"),
          "string"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "MAE",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.mae),
          "number"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "Model_Training_Result",
        "NMAE",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, this.state.GetTrainingResultsFromBucket?.trainingResults?.nmae),
          "number"
        )
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.Model_Training_ListComponent_14934_onClick8_,
        "Model_Training_List",
        "Model_Training_Result",
        "",
        "9924ef5f-4b84-4200-b624-8d76348c7686",
        "627596",
        null,
        null,
        "true",
        "800px",
        "70vh",
        true
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_14934_onClick8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Model_Training_ListComponent_14934_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.GetTrainingResultsFromBucket?.result),
        "success"
      )
    ) {
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_14934_onClick9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_14934_onClick9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Model_Training_ListComponent_14934_onClick9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.GetTrainingResultsFromBucket?.result),
        "success"
      )
    ) {
      stateVars.dataSource_795226 = this.state.GetModels;

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  Model_Training_ListComponent_252302_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Model_Training_List/Model_Training_ListComponent_252302_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetGCPAuthToken = result?.data.getGCPAuthToken;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Model_Training_ListComponent_252302_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_252302_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      gcpAuthToken_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetGCPAuthToken),
        "string"
      ),
      customJobName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "vertexCustomJobName"),
        "string"
      )
    };

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "b6c05ce4_b692_59a9_b98e_3caa60f98ee4_confirmation",
        this.defaultML,
        "Model eğitimini iptal etmek istediğinize emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-roboto"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Model_Training_List/Model_Training_ListComponent_252302_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.CancelVertexAiCustomJob = result?.data.cancelVertexAiCustomJob;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_252302_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_252302_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ModelId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "modelID"),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CancelVertexAiCustomJob?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Model_Training_List/Model_Training_ListComponent_252302_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UpdateModelAfterTrainingCancel = result?.data.updateModelAfterTrainingCancel;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_252302_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_252302_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_252302_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TableName_0: ReactSystemFunctions.convertToTypeByName("Model", "string"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "id"),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CancelVertexAiCustomJob?.result),
        "error"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Model_Training_List/Model_Training_ListComponent_252302_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UpdateModelOrPredictionAfterCancelFail = result?.data.updateModelOrPredictionAfterCancelFail;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_252302_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_252302_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_252302_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Status_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "current", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Model_Training_List/Model_Training_ListComponent_252302_onClick4_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetModels = result?.data.getModels;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Model_Training_ListComponent_252302_onClick5_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_252302_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "6ef462cc_d7e5_09ad_e867_d9682c958947_notify",
        this.defaultML,
        "Model eğitim işlemi iptal edildi."
      ),
      ReactSystemFunctions.convertToTypeByName("success", "string"),
      ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
      ReactSystemFunctions.convertToTypeByName("5", "number"),
      ReactSystemFunctions.convertToTypeByName("", "string"),
      ReactSystemFunctions.convertToTypeByName("", "string"),
      ReactSystemFunctions.convertToTypeByName("0", "number")
    );

    stateVars.dataSource_795226 = this.state.GetModels;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Model_Training_ListComponent_573776_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProjectId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "projectID"),
        "Guid"
      ),
      jsonString_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GetProjectRequirementsAsJson?.length > 0
            ? stateVars.GetProjectRequirementsAsJson[0].requirementsAsJson
            : this.state.GetProjectRequirementsAsJson?.length > 0
            ? this.state.GetProjectRequirementsAsJson[0].requirementsAsJson
            : null
        ),
        "string"
      ),
      gcpAccessToken_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, stateVars.GetGCPAuthToken),
        "string"
      ),
      prefix_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "projectName"),
        "string"
      ),
      folderName_2: ReactSystemFunctions.convertToTypeByName("data", "string"),
      parentModelId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "parentModelID"),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Model_Training_List/Model_Training_ListComponent_573776_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetGCPAuthToken = result?.data.getGCPAuthToken;
    stateVars.GetProjectRequirementsAsJson = result?.data.getProjectRequirementsAsJson;
    stateVars.SaveJsonToBucket = result?.data.saveJsonToBucket;
    stateVars.GetParentModelPathFromParentModelId = result?.data.getParentModelPathFromParentModelId;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Model_Training_ListComponent_573776_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_573776_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.SaveJsonToBucket?.result), "error")
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.toString(this, this.state.SaveJsonToBucket?.message),
        ReactSystemFunctions.convertToTypeByName("danger", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("5", "number"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("0", "number")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_573776_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_573776_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Model_Training_ListComponent_573776_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      targetKey_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "metricName"),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Model_Training_List/Model_Training_ListComponent_573776_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetVertexAiApiCompatibleTargetKey = result?.data.getVertexAiApiCompatibleTargetKey;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Model_Training_ListComponent_573776_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_573776_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      gcpAuthToken_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetGCPAuthToken),
        "string"
      ),
      task_0: ReactSystemFunctions.convertToTypeByName("train", "string"),
      modelBucketPath_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetParentModelPathFromParentModelId),
        "string"
      ),
      jsonBucketPath_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.SaveJsonToBucket?.bucketPath),
        "string"
      ),
      targetKey_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetVertexAiApiCompatibleTargetKey),
        "string"
      ),
      jobId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "modelID"),
        "string"
      ),
      userId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserID
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserID
            : null
        ),
        "string"
      ),
      modelTypeCode_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "language"),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.SaveJsonToBucket?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Model_Training_List/Model_Training_ListComponent_573776_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.CreateVertexAiCustomJob = result?.data.createVertexAiCustomJob;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_573776_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_573776_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_573776_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      StartTime_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      VertexCustomJobName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.customJob?.name),
        "string"
      ),
      ModelId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "modelID"),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Model_Training_List/Model_Training_ListComponent_573776_onClick4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UpdateModelAfterTrainingStart = result?.data.updateModelAfterTrainingStart;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_573776_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_573776_onClick5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_573776_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.result),
        "error"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.message),
        ReactSystemFunctions.convertToTypeByName("danger", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("3", "number"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("0", "number")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_573776_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_573776_onClick6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Model_Training_ListComponent_573776_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.result),
        "success"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "0c425e26_7f86_6137_acb9_e644b6d9d298_notify",
          this.defaultML,
          "Model eğitim işlemi başladı."
        ),
        ReactSystemFunctions.convertToTypeByName("success", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("5", "number"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("", "string"),
        ReactSystemFunctions.convertToTypeByName("0", "number")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_573776_onClick7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_573776_onClick7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Model_Training_ListComponent_573776_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Status_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "current", "", "", "")
        ),
        "number"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.result),
        "success"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Model_Training_List/Model_Training_ListComponent_573776_onClick7_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GetModels = result?.data.getModels;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_573776_onClick8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_573776_onClick8_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_573776_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.result),
        "success"
      )
    ) {
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_573776_onClick9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Model_Training_ListComponent_573776_onClick9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Model_Training_ListComponent_573776_onClick9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.CreateVertexAiCustomJob?.result),
        "success"
      )
    ) {
      stateVars.dataSource_795226 = this.state.GetModels;

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  Model_Training_ListComponent_658618_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Status_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].companyId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].companyId
            : null
        ),
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Model_Training_List/Model_Training_ListComponent_658618_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetModels = result?.data.getModels;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Model_Training_ListComponent_658618_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_658618_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_795226 = this.state.GetModels;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Model_Training_ListComponent_795226_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [, 511494, 792729, 314747, 627596] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.Model_Training_ListPageInit();
    }
    if (diId == 314747) {
      isErrorOccurred = await this.Model_Training_ListComponent_14934_onClick7_();
      if (isErrorOccurred) return true;
    } else if (diId == 627596) {
      isErrorOccurred = await this.Model_Training_ListComponent_14934_onClick8_();
      if (isErrorOccurred) return true;
    }
  }
}
