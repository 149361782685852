import { Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import {
  Button,
  HorizontalStack,
  Icon,
  KCol,
  KModal,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label
} from "../../components/web-components";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { SiskonMaster_Screen } from "../siskonmaster/siskonmaster";
import { Benchmark_Project_List_ScreenBase } from "./benchmark_project_list-base";

import { Benchmark_Project_Form_Screen } from "../benchmark_project_form/benchmark_project_form";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class Benchmark_Project_List_Screen extends Benchmark_Project_List_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <SiskonMaster_Screen
          form={this.props.form}
          initialValues={{}}
          screenInputs={this.props.screenInputs}
          setMasterPageInitLoaded={this.setMasterPageInitLoaded}
          setMasterPageRecurringLoaded={this.setMasterPageRecurringLoaded}
          isMasterPageInitLoaded={this.state.isMasterPageInitLoaded}
          isMasterPageRecurringLoaded={this.state.isMasterPageRecurringLoaded}
          shouldTriggerPageInit={this.state.shouldTriggerPageInit}
          setShouldTriggerPageInit={this.setShouldTriggerPageInit}
        >
          <Spin spinning={this.state.spinnerCount > 0}>
            <div
              id="benchmark_project_list_body"
              style={
                {
                  backgroundColor: "rgba(247, 247, 249, 1)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "left",
                  display: "block",
                  overflow: "auto",
                  minHeight: "calc(100vh - 60px - 60px - 0px - 0px)",
                  maxHeight: "calc(100vh - 60px - 60px - 0px - 0px)",
                  height: "calc(100vh - 60px - 60px - 0px - 0px)"
                } as any
              }
            >
              <KRow
                id="73140"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)"
                  } as any
                }
              >
                <KCol
                  id="615359"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 16,
                      paddingRight: 10,
                      paddingBottom: 10,
                      paddingLeft: 10,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <Label
                    id="178270"
                    value={ReactSystemFunctions.translate(this.ml, 178270, "value", this.defaultML)}
                    style={
                      {
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        display: "inline",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "18px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  ></Label>
                </KCol>
              </KRow>

              <KRow
                id="131548"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)"
                  } as any
                }
              >
                <KCol
                  id="136710"
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={12}
                  xxl={12}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 10,
                      paddingRight: 10,
                      paddingBottom: 10,
                      paddingLeft: 16,
                      alignItems: "center",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <Button
                    id="243559"
                    onClick={(e?: any) => {
                      if (e && e.stopPropagation) e.stopPropagation();
                      this.Benchmark_Project_ListComponent_243559_onClick();
                    }}
                    showCursorPointer
                    label={ReactSystemFunctions.translate(this.ml, 243559, "label", this.defaultML)}
                    size="middle"
                    loading={false}
                    ghost={false}
                    block={false}
                    htmlType="button"
                    icon="add"
                    iconPosition="left"
                    danger={false}
                    style={
                      {
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(17, 144, 205, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingTop: 8,
                        paddingRight: 16,
                        paddingBottom: 8,
                        paddingLeft: 16,
                        alignItems: "center",
                        textAlign: "-webkit-center",
                        display: "inline",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "rgba(255, 255, 255, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  ></Button>
                </KCol>

                <KCol
                  id="186378"
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={12}
                  xxl={12}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 10,
                      paddingRight: 10,
                      paddingBottom: 10,
                      paddingLeft: 10,
                      alignItems: "flex-start",
                      textAlign: "-webkit-right",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <KRow
                    id="293460"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="302072"
                      xs={8}
                      sm={8}
                      md={8}
                      lg={8}
                      xl={24}
                      xxl={24}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 10,
                          paddingRight: 10,
                          paddingBottom: 10,
                          paddingLeft: 10,
                          alignItems: "flex-start",
                          textAlign: "-webkit-right",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <HorizontalStack
                        id="522652"
                        direction="horizontal"
                        size={12}
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      ></HorizontalStack>
                    </KCol>
                  </KRow>
                </KCol>
              </KRow>

              <KRow
                id="321806"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 16,
                    paddingBottom: 0,
                    paddingLeft: 16,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)"
                  } as any
                }
              >
                <KCol
                  id="674100"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderTopLeftRadius: 8,
                      borderTopRightRadius: 8,
                      borderBottomRightRadius: 8,
                      borderBottomLeftRadius: 8,
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      paddingTop: 24,
                      paddingRight: 10,
                      paddingBottom: 10,
                      paddingLeft: 16,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <KTable
                    id="151761"
                    kuikaRef={this.benchmark_project_list_151761_value_kuikaTableRef}
                    form={this.props.form}
                    dataSource={this.state.GetBenchmarkProjects}
                    size="middle"
                    bordered={true}
                    loading={false}
                    transformedOnMobileResolution={false}
                    showHeader={true}
                    fixedHeader={false}
                    editable={false}
                    tableWidthMode="fit"
                    globalSearch={true}
                    searchable={false}
                    sorter={true}
                    filtering={false}
                    pagination={true}
                    pageSize={10}
                    showSizeChanger={true}
                    columnChooser={true}
                    resizableColumns={false}
                    striped={true}
                    stripedColor="#F5F7FA"
                    rowHoverBgColor="#fafafa"
                    insertRowActive={false}
                    insertRowPosition="top"
                    showNoDataFound={true}
                    nodatafoundmessage={ReactSystemFunctions.translate(
                      this.ml,
                      151761,
                      "nodatafoundmessage",
                      this.defaultML
                    )}
                    multiSelect={false}
                    export={false}
                    editableAlwaysActive={false}
                    hideHeaderOnMobileResolution={false}
                    style={
                      {
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(0, 0, 0, 0)",
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <KTableHeader
                      id="63573"
                      hideOnMobileResolution={false}
                      textDirection="Default"
                      style={
                        {
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "12px",
                          color: "rgba(155, 155, 155, 1)",
                          lineHeight: "20px"
                        } as any
                      }
                    ></KTableHeader>

                    <KTableRow
                      id="230172"
                      hoverFontColor="red"
                      hoverBgColor="#F5F5F5"
                      style={
                        {
                          borderTopWidth: 1,
                          borderColor: "rgba(217, 217, 217, 1)",
                          borderStyle: "solid",
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <KTableColumn
                        id="448911"
                        title={ReactSystemFunctions.translate(this.ml, 448911, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        footerFontSize={14}
                        footerFontColor="rgb(68, 68, 68)"
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="869051"
                          direction="horizontal"
                          size={12}
                          style={
                            {
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Icon
                            id="298346"
                            onClick={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.Benchmark_Project_ListComponent_298346_onClick();
                            }}
                            showCursorPointer
                            iconName="edit"
                            hoverText="Düzenle"
                            style={
                              {
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(17, 144, 205, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>

                          <Icon
                            id="866475"
                            onClick={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.Benchmark_Project_ListComponent_866475_onClick();
                            }}
                            showCursorPointer
                            iconName="delete_outline"
                            hoverText="Sil"
                            style={
                              {
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(250, 84, 28, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>
                        </HorizontalStack>
                      </KTableColumn>

                      <KTableColumn
                        id="41433"
                        title={ReactSystemFunctions.translate(this.ml, 41433, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        footerFontSize={14}
                        footerFontColor="rgb(68, 68, 68)"
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="177469"
                          value="[datafield:projectName]"
                          style={
                            {
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="910241"
                        title={ReactSystemFunctions.translate(this.ml, 910241, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        footerFontSize={14}
                        footerFontColor="rgb(68, 68, 68)"
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="269661"
                          value="[datafield:sectorName]"
                          style={
                            {
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="708061"
                        title={ReactSystemFunctions.translate(this.ml, 708061, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        footerFontSize={14}
                        footerFontColor="rgb(68, 68, 68)"
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="92957"
                          value="[datafield:architectureName]"
                          style={
                            {
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="611655"
                        title={ReactSystemFunctions.translate(this.ml, 611655, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        footerFontSize={14}
                        footerFontColor="rgb(68, 68, 68)"
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="85829"
                          value="[datafield:softwareDevelopmentTypeName]"
                          style={
                            {
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="58179"
                        title={ReactSystemFunctions.translate(this.ml, 58179, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        footerFontSize={14}
                        footerFontColor="rgb(68, 68, 68)"
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="221555"
                          value="[datafield:softwareDevelopmentMethodologyName]"
                          style={
                            {
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="264710"
                        title={ReactSystemFunctions.translate(this.ml, 264710, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        footerFontSize={14}
                        footerFontColor="rgb(68, 68, 68)"
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="987394"
                          value="[datafield:sdlcActivities]"
                          style={
                            {
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="380039"
                        title={ReactSystemFunctions.translate(this.ml, 380039, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        footerFontSize={14}
                        footerFontColor="rgb(68, 68, 68)"
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="587887"
                          value="[datafield:programmingLanguages]"
                          style={
                            {
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="916123"
                        title={ReactSystemFunctions.translate(this.ml, 916123, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        footerFontSize={14}
                        footerFontColor="rgb(68, 68, 68)"
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="81219"
                          value="[datafield:effort]"
                          style={
                            {
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="425999"
                        title={ReactSystemFunctions.translate(this.ml, 425999, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        footerFontSize={14}
                        footerFontColor="rgb(68, 68, 68)"
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="571023"
                          value="[datafield:eventTotalEp]"
                          style={
                            {
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="327849"
                        title={ReactSystemFunctions.translate(this.ml, 327849, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        footerFontSize={14}
                        footerFontColor="rgb(68, 68, 68)"
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="486655"
                          value="[datafield:cosmicTotalCfp]"
                          style={
                            {
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>
                    </KTableRow>
                  </KTable>
                </KCol>
              </KRow>

              <KModal
                id="363205"
                visible={this.state.NavVisible363205}
                onOk={() => {
                  this.setState({ NavVisible363205: false }, () => {
                    this.callCallbackFunction(363205);
                  });
                }}
                onCancel={() => {
                  this.setState({ NavVisible363205: false }, () => {
                    this.callCallbackFunction(363205);
                  });
                }}
                closable={true}
                width="1000px"
                footer={null}
                height="70vh"
                children={
                  this.state.NavVisible363205 && (
                    <Benchmark_Project_Form_Screen
                      onClose={() => {
                        this.setState({ NavVisible363205: false }, () => {
                          this.callCallbackFunction(363205);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                      navigator={this.props.navigator}
                      form={this.props.form}
                      context={this.props.context}
                    />
                  )
                }
              ></KModal>

              <KModal
                id="473238"
                visible={this.state.NavVisible473238}
                onOk={() => {
                  this.setState({ NavVisible473238: false }, () => {
                    this.callCallbackFunction(473238);
                  });
                }}
                onCancel={() => {
                  this.setState({ NavVisible473238: false }, () => {
                    this.callCallbackFunction(473238);
                  });
                }}
                closable={true}
                width="1000px"
                footer={null}
                height="70vh"
                children={
                  this.state.NavVisible473238 && (
                    <Benchmark_Project_Form_Screen
                      onClose={() => {
                        this.setState({ NavVisible473238: false }, () => {
                          this.callCallbackFunction(473238);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                      navigator={this.props.navigator}
                      form={this.props.form}
                      context={this.props.context}
                    />
                  )
                }
              ></KModal>
            </div>
          </Spin>
        </SiskonMaster_Screen>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(Benchmark_Project_List_Screen))))
  )
);
export { tmp as Benchmark_Project_List_Screen };
//export default tmp;
//export { tmp as Benchmark_Project_List_Screen };
